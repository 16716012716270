<template>
  <div
    v-if="show"
    class="rounded-md bg-transparent p-4 border"
    :class="{
      'border-success-500': type === 'success',
      'border-danger-500': type === 'danger',
      'border-info-500': type === 'info',
      'border-warning-500': type === 'warning',
    }"
  >
    <div class="flex items-center">
      <div class="flex-shrink-0">
        <Icon
          :name="icon"
          class="h-6 w-6"
          :class="{
            'text-success-400': type === 'success',
            'text-danger-500': type === 'danger',
            'text-info-500': type === 'info',
            'text-warning-500': type === 'warning',
          }"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <p
          class="text-sm font-bold"
          :class="{
            'text-success-500': type === 'success',
            'text-danger-500': type === 'danger',
            'text-info-500': type === 'info',
            'text-warning-500': type === 'warning',
          }"
        >
          {{ message }}
        </p>
      </div>
      <div v-if="dismissable" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            class="inline-flex rounded-md p-1.5 focus:outline-none hover:ring-1"
            :class="{
              'text-success-400 ring-success-400': type === 'success',
              'text-danger-400 ring-danger-400': type === 'danger',
              'text-info-400 ring-info-400': type === 'info',
              'text-warning-400 ring-warning-400': type === 'warning',
            }"
            @click="show = false"
          >
            <span class="sr-only">Dismiss</span>
            <Icon name="heroicons:x-mark" class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  type: {
    type: String,
    default: 'success',
  },
  message: {
    type: String,
    default: '',
  },
  dismissable: {
    type: Boolean,
    required: false,
    default: true,
  },
}) as {
  modelValue: boolean
  type: 'success' | 'danger' | 'warning' | 'info'
  message: string
}

const emit = defineEmits(['update:modelValue'])

const show = computed<boolean>({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const icon = computed<string>(() => {
  switch (props.type) {
    case 'success':
      return 'heroicons:check-circle'
    case 'danger':
      return 'heroicons:x-circle'
    case 'warning':
      return 'heroicons:exclamation-circle'
    case 'info':
      return 'heroicons:information-circle'
  }
})
</script>

<style scoped></style>
